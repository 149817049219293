@import '../../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* CAROUSEL */

.carousel-indicators {
    li {
        background: $gray-lighten;
        opacity: .5;
        &.active {
            opacity: 1;
            background: $gray-lighten;
        }
    }
}