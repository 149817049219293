@import "../../../mixins";

.overlay-loading {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #fff8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ck {
  &.ck-toolbar {
    &__separator {
      background-color: $gray-border !important;
    }
    &.ck-toolbar_grouping {
      border-color: $gray-border;
    }
  }
  &.ck-editor__main {
    & > .ck-editor__editable:not(.ck-focused) {
      border-color: $gray-border !important;
    }
  }
}

.has-error {
  .ant-picker,
  .ant-select-selector {
    border-color: $danger !important;
  }
  .ck {
    &.ck-toolbar {
      &__separator {
        background-color: $gray-border !important;
      }
      &.ck-toolbar_grouping {
        border-color: $gray-border;
      }
    }
    &.ck-editor__main {
      & > .ck-editor__editable:not(.ck-focused) {
        border-color: $danger !important;
      }
    }
  }
}

.ant-modal {
  max-width: unset;
}

.anticon {
  vertical-align: 0.025em;
}

@keyframes logoAnim {
  0% {
    transform: scale(0);
    -ms-transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    -ms-transform: scale(1);
    opacity: 1;
  }
}

.utils__loadingPage {
  &__logo {
    height: 200px;
    animation-name: logoAnim;
    animation-duration: 1s;
  }
  background-image: unset;
  background-color: $black-lighten;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-input[disabled],
.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector,
.ant-picker.ant-picker-disabled,
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  color: rgba($color: #000000, $alpha: 0.85);
  background-color: #eeec;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item,
.ant-picker-input > input[disabled] {
  color: rgba($color: #000000, $alpha: 0.85);
}

.ant-input-sm {
  padding: 0px 7px;
  height: 24px;
}

.editable-row {
  .ant-legacy-form-explain,
  .ant-form-explain {
    display: none;
  }
  .ant-legacy-form-item {
    padding-bottom: 0px !important;
  }
  textarea.ant-input {
    margin-bottom: 0px !important;
  }
}

.p-no-mb {
  p {
    margin-bottom: 0;
  }
}

.p-line-height-1 {
  p {
    line-height: 1;
  }
}

tr.done-row {
  background-color: rgba($color: #dddddd, $alpha: 1);
  opacity: 0.5;
}

.breadcrumbBar {
  margin-right: 0;
  margin-left: 0;
}

.utils__content {
  padding-left: 0;
  padding-right: 0;
}

.card-body {
  padding: 1.92rem 0.5rem !important;
}

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
