@import "../../../../resources/mixins";

.notification-item {
  padding: 20px 15px 20px 10px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
  &.unread {
    background-color: rgba($color: $primary, $alpha: 0.1);
    &:hover {
      background-color: rgba($color: $primary, $alpha: 0.2);
    }
  }
  .humanized-time {
    font-size: 10px;
    color: $secondary;
  }
}

.ant-drawer-body {
  position: relative;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  .noti-drawer {
    height: 100%;
    .noti-infinite-container {
      height: 100%;
      overflow: auto;
      .new-noti-button-wrapper {
        display: none;
        text-align: center;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        padding-top: 10px;
      }
    }
    &.show-new-not-btn {
      .new-noti-button-wrapper {
        display: block;
      }
    }
  }
}
