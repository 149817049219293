@charset "UTF-8";
.breadcrumbBar {
  padding: 0.76rem 1.53rem;
  margin: -2.31rem;
  margin-bottom: 2.3rem;
  background-color: #ffffff;
  border-bottom: 1px solid #e4e9f0; }
  @media (max-width: 768px) {
    .breadcrumbBar {
      margin-left: -1.54rem;
      margin-right: -1.54rem; } }
  .breadcrumbBar__path {
    font-size: 1.38rem;
    color: #0e0b20; }
  .breadcrumbBar__arrow {
    display: inline-block;
    margin: 0 0.76rem; }
    .breadcrumbBar__arrow:after {
      display: inline-block;
      content: '·'; }
