// Import mixins from "core" module
@import '../../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN MENU */

.ant-menu-vertical .ant-menu-item, .ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item, .ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title, .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-submenu-title, .ant-menu-inline .ant-menu-submenu-title {
  font-size: 13px;
}